/* src/custom.css */

.custom-prev,
.custom-next {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}

.custom-prev {
  left: 10px;
}

.custom-next {
  right: 10px;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}
